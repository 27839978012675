import * as React from 'react';
import { ToastContainer, toast, ToastOptions, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IToastMessage } from '../Main/interfaces';
import { alertToast, popToast } from '../Main/slice';

const Alert: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const alertList: IToastMessage[] = useAppSelector(alertToast);

    const opts: ToastOptions = React.useMemo(() => ({
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        transition: Zoom,
    }), []);

    React.useEffect(() => {
        alertList.forEach(alert => {
            switch(alert.type){
                case 'error':
                    toast.error(alert.message, opts);
                    break;
                case 'info':
                    toast.info(alert.message, opts);
                    break;
                case 'success':
                    toast.success(alert.message, opts);
                    break;
                case 'warning':
                    toast.warn(alert.message, opts);
                    break;
                default:
                    toast(alert.message, opts);
            }
        });
        dispatch(popToast());
    },[opts, dispatch, alertList]);


    return(
        <ToastContainer />
    );
}

export default Alert;