import * as React from 'react';
import Modal from 'react-bootstrap/Modal';import Button from 'react-bootstrap/Button';

interface IModalProps {
    show: boolean;
    handleClose: () => void;
    bodyMsje: string;
    fijo: boolean;
}

const SimpleModal: React.FunctionComponent<IModalProps> = (props) => {
    const {show, handleClose, bodyMsje, fijo} = props;
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={fijo?'static': undefined }
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>¡Atención!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{bodyMsje}</span>
            </Modal.Body>
            <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default SimpleModal;