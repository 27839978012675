import * as React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';  
import { publicRoutes } from '../../config/routes';

function RootLoginMain () {
    return class extends React.Component {
        render() {
            return (
                <Router>
                    <Routes>
                        {publicRoutes.map(r => <Route key={r.path} path={r.path} element={React.createElement(r.component)} />)}
                    </Routes>
                </Router>
            );
        }
    }
}

export const PublicRoutes = RootLoginMain();