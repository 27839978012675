import { Row } from "read-excel-file";
import { IPlantillaContratistaObject, IPlantillaDotacionMineraObject, IPlantillaDotacionProveedorObject } from "./interfaces";

export function parseDate (date: Date): string {
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
}

export function fileRowToPlantillaContratistas (rows: Row[]): IPlantillaContratistaObject[] {
    let list: IPlantillaContratistaObject[] = rows.map(r => ({
        plaNumeroIdentificador: r[0],
        plaRazonSocial: r[1],
        plaNombreEmpresa: r[2],
        plaNumPersonasPermanenteFaenaHombre: r[3],
        plaNumPersonasPermanenteFaenaMujer: r[4],
        plaNumTotalPersonasPermanentesenFaena: r[5],
        plaCategoria: r[6],
    } as IPlantillaContratistaObject));
    return list.slice(1);
}

export function fileRowToPlantillaDotacionMinera (rows: Row[]): IPlantillaDotacionMineraObject[] {
    let list: IPlantillaDotacionMineraObject[] = rows.map(r => ({
        plaNumeroIdentificador: r[0],
        plaFaenaOperacionDivisionMatriz: r[1],
        plaGerencia: r[2],
        plaSubgerencia: r[3],
        plaUnidad: r[4],
        plaDepartamento: r[5],
        plaArea: r[6],
        plaCargoId: r[7],
        plaCargoEspecifico: r[8],
        plaFechadeNacimiento: r[9],
        plaSexo: r[10],
        plaNacionalidad: r[11],
        plaPuebloOriginario: r[12],
        plaRegiondeResidenciaPermanente: r[13],
        plaNivelEducacionalAprobado: r[14],
        plaFechadeIngresoalaEmpresa: r[15],
    } as IPlantillaDotacionMineraObject));
    return list.slice(1);
}

export function fileRowToPlantillaDotacionProveedor (rows: Row[]): IPlantillaDotacionProveedorObject[] {
    let list: IPlantillaDotacionProveedorObject[] = rows.map(r => ({
        plaNumeroIdentificador: r[0],
        plaGerencia: r[1],
        plaSubgerencia: r[2],
        plaUnidad: r[3], 
        plaDepartamento: r[4],
        plaArea: r[5],
        plaCargoId: r[6],
        plaCargoEspecifico: r[7],
        plaLugardeTrabajo: r[8],
        plaFechadeNacimiento: r[9],
        plaSexo: r[10],
        plaNacionalidad: r[11],
        plaPuebloOriginario: r[12],
        plaRegiondeResidenciaPermanente: r[13],
        plaRegiondeTrabajo: r[14],
        plaNivelEducacional: r[15],
        plaFechadeIngresoalaEmpresa: r[16],
    } as IPlantillaDotacionProveedorObject));
    return list.slice(1);
}