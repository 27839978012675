import apiConfig from '../../config';
import { IRequest, IDotacionMineraRequest, IContratistaPermanenteRequest, IDotacionProveedoraRequest, IDownloadFileRequest, IPlantillaDotacionProveedorObject, IPlantillaDotacionMineraObject, IPlantillaContratistaObject } from '../../shared/interfaces';

export function fetchFormsList(idEmpresa: number): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    fetch(`${apiConfig.apiUri}/api/Planilla/ToListEmpresaPlanillaAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify({
        "empEmpresaId": idEmpresa,
      }),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as Promise<any>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function fetchFaenasListByEmpresa(idEmpresa: number): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    fetch(`${apiConfig.apiUri}/api/Maestra/ToListFaenaAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify(idEmpresa),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as Promise<any>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function fetchTipoDocumento(idEmpresa: number, idFaena?: number): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    fetch(`${apiConfig.apiUri}/api/Planilla/ToListPlanillaEmpresaFaenaAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify(
        {
          "faFaenaId": idFaena,
          "empEmpresaId": idEmpresa
        }),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as Promise<any>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaContratista(request: IContratistaPermanenteRequest): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    let bodyContenidoArchivo: IPlantillaContratistaObject[] = [];
    for (let i = 0; i < request.plantillaContratistaPermanenteDto.length; i++) {
      if (request.plantillaContratistaPermanenteDto[i].plaCategoria !== null) {
        request.plantillaContratistaPermanenteDto[i].plaCategoria = request.plantillaContratistaPermanenteDto[i].plaCategoria.toString();
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNombreEmpresa !== null) {
        request.plantillaContratistaPermanenteDto[i].plaNombreEmpresa = request.plantillaContratistaPermanenteDto[i].plaNombreEmpresa.toString();
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNumeroIdentificador !== null) {
        request.plantillaContratistaPermanenteDto[i].plaNumeroIdentificador = request.plantillaContratistaPermanenteDto[i].plaNumeroIdentificador.toString();
      }
      if (request.plantillaContratistaPermanenteDto[i].plaRazonSocial !== null) {
        request.plantillaContratistaPermanenteDto[i].plaRazonSocial = request.plantillaContratistaPermanenteDto[i].plaRazonSocial.toString();
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaHombre !== null) {
        try {
          request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaHombre = Number(request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaHombre.toFixed(0));
        } catch (error) {
          if (error) throw Error(`El valor ${request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaHombre} ingresado en el campo "Número de personas permanentes en faena hombres" no es válido (Columna ${i + 1}).`);
        }
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaMujer !== null) {
        try {
          request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaMujer = Number(request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaMujer.toFixed(0));
        } catch (error) {
          if (error) throw Error(`El valor "${request.plantillaContratistaPermanenteDto[i].plaNumPersonasPermanenteFaenaMujer}" ingresado en el campo "Número de personas permanentes en faena mujeres" no es válido (Columna ${i + 1}).`);
        }
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNumTotalPersonasPermanentesenFaena !== null) {
        try {
          request.plantillaContratistaPermanenteDto[i].plaNumTotalPersonasPermanentesenFaena = Number(request.plantillaContratistaPermanenteDto[i].plaNumTotalPersonasPermanentesenFaena.toFixed(0));
        } catch (error) {
          if (error) throw Error(`El valor "${request.plantillaContratistaPermanenteDto[i].plaNumTotalPersonasPermanentesenFaena}" ingresado en el campo "Número total de personas permanentes en faena" no es válido (Columna ${i + 1}).`);
        }
      }
      if (request.plantillaContratistaPermanenteDto[i].plaNumeroIdentificador !== null) {
        bodyContenidoArchivo.push(request.plantillaContratistaPermanenteDto[i]);
      }
    }
    fetch(`${apiConfig.apiUri}/api/Planilla/AddContratistaPermanenteAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify({
        "EmpEmpresaId": request.empEmpresaId,
        "PerPeriodoId": request.perPeriodoId,
        "EstEstadoPlanillaId": 1,
        "UsuUsuarioId": request.usuUsuarioId,
        "DocNombre": request.docNombre,
        "FaFaenaId": request.faFaenaId,
        "EliminarDatosAnteriores": request.eliminarDatosAnteriores,
        "IdentificadorArchivo": request.docIdentificador,
        "PlantillaContratistaPermanenteDto": bodyContenidoArchivo
      }),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: String(bodyContenidoArchivo.length),
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaContratistaFile(request: IContratistaPermanenteRequest, response: string): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    let formData = new FormData();
    formData.append("Archivo.File", request.archivo);
    formData.append("Archivo.Rut", request.empRut);
    formData.append("Archivo.Periodo", String(request.periodoA));
    formData.append("Archivo.TipoPlanilla", "1");
    formData.append('Archivo.IdentificadorArchivo', response);
    fetch(`${apiConfig.apiUri}/api/Planilla/AddContratistaPermanenteSHPAsync`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: formData,
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.status.toString() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaDotacionProveedora(request: IDotacionProveedoraRequest): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    let bodyContenidoArchivo: IPlantillaDotacionProveedorObject[] = [];
    for (let i = 0; i < request.plantillaDocDotacionProveedoraDto.length; i++) {
      if (request.plantillaDocDotacionProveedoraDto[i].plaArea !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaArea = request.plantillaDocDotacionProveedoraDto[i].plaArea.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaCargoEspecifico !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaCargoEspecifico = request.plantillaDocDotacionProveedoraDto[i].plaCargoEspecifico.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaCargoId !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaCargoId = request.plantillaDocDotacionProveedoraDto[i].plaCargoId.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaDepartamento !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaDepartamento = request.plantillaDocDotacionProveedoraDto[i].plaDepartamento.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaFechadeIngresoalaEmpresa !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaFechadeIngresoalaEmpresa = request.plantillaDocDotacionProveedoraDto[i].plaFechadeIngresoalaEmpresa.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaFechadeNacimiento !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaFechadeNacimiento = request.plantillaDocDotacionProveedoraDto[i].plaFechadeNacimiento.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaGerencia !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaGerencia = request.plantillaDocDotacionProveedoraDto[i].plaGerencia.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaLugardeTrabajo !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaLugardeTrabajo = request.plantillaDocDotacionProveedoraDto[i].plaLugardeTrabajo.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaNacionalidad !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaNacionalidad = request.plantillaDocDotacionProveedoraDto[i].plaNacionalidad.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaNivelEducacional !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaNivelEducacional = request.plantillaDocDotacionProveedoraDto[i].plaNivelEducacional.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaNumeroIdentificador !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaNumeroIdentificador = request.plantillaDocDotacionProveedoraDto[i].plaNumeroIdentificador.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaPuebloOriginario !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaPuebloOriginario = request.plantillaDocDotacionProveedoraDto[i].plaPuebloOriginario.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaRegiondeResidenciaPermanente !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaRegiondeResidenciaPermanente = request.plantillaDocDotacionProveedoraDto[i].plaRegiondeResidenciaPermanente.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaRegiondeTrabajo !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaRegiondeTrabajo = request.plantillaDocDotacionProveedoraDto[i].plaRegiondeTrabajo.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaSexo !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaSexo = request.plantillaDocDotacionProveedoraDto[i].plaSexo.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaSubgerencia !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaSubgerencia = request.plantillaDocDotacionProveedoraDto[i].plaSubgerencia.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaUnidad !== null) {
        request.plantillaDocDotacionProveedoraDto[i].plaUnidad = request.plantillaDocDotacionProveedoraDto[i].plaUnidad.toString();
      }
      if (request.plantillaDocDotacionProveedoraDto[i].plaNumeroIdentificador !== null) {
        bodyContenidoArchivo.push(request.plantillaDocDotacionProveedoraDto[i]);
      }
    }
    fetch(`${apiConfig.apiUri}/api/Planilla/AddDotacionProveedoraAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify({
        "EmpEmpresaId": String(request.empEmpresaId),
        "PerPeriodoId": String(request.perPeriodoId),
        "EstEstadoPlanillaId": String(request.estEstadoPlanillaId),
        "UsuUsuarioId": String(request.usuUsuarioId),
        "DocNombre": String(request.docNombre),
        "FaFaenaId": String(request.faFaenaId),
        "EliminarDatosAnteriores": request.eliminarDatosAnteriores,
        "IdentificadorArchivo": String(request.docIdentificador),
        "PlantillaDocDotacionProveedoraDto": bodyContenidoArchivo
      }),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: String(bodyContenidoArchivo.length),
        friendlyMessage: 'Datos agregados correctamente',
        ok: true,
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaDotacionProveedoraFile(request: IDotacionProveedoraRequest, response: string): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    let formData = new FormData();
    formData.append("Archivo.File", request.archivo);
    formData.append("Archivo.Rut", request.empRut);
    formData.append("Archivo.Periodo", String(request.periodoA));
    formData.append("Archivo.TipoPlanilla", "3");
    //formData.append('Archivo.EliminarDatosAnteriores', String(request.eliminarDatosAnteriores));
    formData.append('Archivo.IdentificadorArchivo', String(response));
    fetch(`${apiConfig.apiUri}/api/Planilla/AddDotacionProveedoraSHPAsync`, {
      method: 'POST',
      headers: {
        // 'Content-Disposition': 'form-data',
        // 'boundary': '----WebKitFormBoundary7MA4YWxkTrZu0gW',
        // 'content-type': 'multipart/form-data',
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: formData,
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.status.toString() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaDotacionMinera(request: IDotacionMineraRequest): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    let bodyContenidoArchivo: IPlantillaDotacionMineraObject[] = [];
    for (let i = 0; i < request.plantillaDotacionMineraDto.length; i++) {
      if (request.plantillaDotacionMineraDto[i].plaArea !== null) {
        request.plantillaDotacionMineraDto[i].plaArea = request.plantillaDotacionMineraDto[i].plaArea.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaCargoEspecifico !== null) {
        request.plantillaDotacionMineraDto[i].plaCargoEspecifico = request.plantillaDotacionMineraDto[i].plaCargoEspecifico.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaCargoId !== null) {
        request.plantillaDotacionMineraDto[i].plaCargoId = request.plantillaDotacionMineraDto[i].plaCargoId.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaDepartamento !== null) {
        request.plantillaDotacionMineraDto[i].plaDepartamento = request.plantillaDotacionMineraDto[i].plaDepartamento.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaFaenaOperacionDivisionMatriz !== null) {
        request.plantillaDotacionMineraDto[i].plaFaenaOperacionDivisionMatriz = request.plantillaDotacionMineraDto[i].plaFaenaOperacionDivisionMatriz.toString();

      }
      if (request.plantillaDotacionMineraDto[i].plaFechadeIngresoalaEmpresa !== null) {
        request.plantillaDotacionMineraDto[i].plaFechadeIngresoalaEmpresa = request.plantillaDotacionMineraDto[i].plaFechadeIngresoalaEmpresa.toString();

      }
      if (request.plantillaDotacionMineraDto[i].plaFechadeNacimiento !== null) {
        request.plantillaDotacionMineraDto[i].plaFechadeNacimiento = request.plantillaDotacionMineraDto[i].plaFechadeNacimiento.toString();

      }
      if (request.plantillaDotacionMineraDto[i].plaGerencia !== null) {
        request.plantillaDotacionMineraDto[i].plaGerencia = request.plantillaDotacionMineraDto[i].plaGerencia.toString();

      }
      if (request.plantillaDotacionMineraDto[i].plaNacionalidad !== null) {
        request.plantillaDotacionMineraDto[i].plaNacionalidad = request.plantillaDotacionMineraDto[i].plaNacionalidad.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaNivelEducacionalAprobado !== null) {
        request.plantillaDotacionMineraDto[i].plaNivelEducacionalAprobado = request.plantillaDotacionMineraDto[i].plaNivelEducacionalAprobado.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaNumeroIdentificador !== null) {
        request.plantillaDotacionMineraDto[i].plaNumeroIdentificador = request.plantillaDotacionMineraDto[i].plaNumeroIdentificador.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaPuebloOriginario !== null) {
        request.plantillaDotacionMineraDto[i].plaPuebloOriginario = request.plantillaDotacionMineraDto[i].plaPuebloOriginario.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaRegiondeResidenciaPermanente !== null) {
        request.plantillaDotacionMineraDto[i].plaRegiondeResidenciaPermanente = request.plantillaDotacionMineraDto[i].plaRegiondeResidenciaPermanente.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaSexo !== null) {
        request.plantillaDotacionMineraDto[i].plaSexo = request.plantillaDotacionMineraDto[i].plaSexo.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaSubgerencia !== null) {
        request.plantillaDotacionMineraDto[i].plaSubgerencia = request.plantillaDotacionMineraDto[i].plaSubgerencia.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaUnidad !== null) {
        request.plantillaDotacionMineraDto[i].plaUnidad = request.plantillaDotacionMineraDto[i].plaUnidad.toString();
      }
      if (request.plantillaDotacionMineraDto[i].plaNumeroIdentificador !== null) {
        bodyContenidoArchivo.push(request.plantillaDotacionMineraDto[i]);
      }
    }
    fetch(`${apiConfig.apiUri}/api/Planilla/AddDotacionMineraAsync`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: JSON.stringify({
        "EmpEmpresaId": request.empEmpresaId,
        "PerPeriodoId": request.perPeriodoId,
        "EstEstadoPlanillaId": 1,
        "UsuUsuarioId": request.usuUsuarioId,
        "DocNombre": request.docNombre,
        "FaFaenaId": request.faFaenaId,
        "EliminarDatosAnteriores": request.eliminarDatosAnteriores,
        "IdentificadorArchivo": request.docIdentificador,
        "PlantillaDotacionMineraDto": bodyContenidoArchivo
      }),
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: String(bodyContenidoArchivo.length),
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function postPlantillaDotacionMineraFile(request: IDotacionMineraRequest, response: string): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {

    let formData = new FormData();
    formData.append('Archivo.File', request.archivo);
    formData.append('Archivo.Rut', String(request.rut));
    formData.append('Archivo.Periodo', String(request.periodo));
    formData.append('Archivo.TipoPlanilla', "2");
    formData.append('Archivo.IdentificadorArchivo', response);
    fetch(`${apiConfig.apiUri}/api/Planilla/AddDotacionMineraSHPAsync`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
      body: formData,
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.status.toString() as unknown as Promise<unknown>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}

export function downloadDocumentoApi(request: IDownloadFileRequest): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    fetch(`${apiConfig.apiUri}/api/Planilla/DownloadTemplatePlanillaSHPAsync?fileNameGet=${request.filename}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
    })
      .then(response => {
        if (!response.ok) throw Error(response.status.toString());
        return response.blob();
      })
      .then((blob: Blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', request.filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode!.removeChild(link);
        resolve({
          data: null,
          msje: 'Success',
          friendlyMessage: 'Descarga correcta.',
          ok: true,
        });
      })
      .catch(err => {
        reject(err)
      });
  });
}

export function fetchPeriodoActivo(): Promise<IRequest> {
  return new Promise<IRequest>((resolve, reject) => {
    fetch(`${apiConfig.apiUri}/api/Maestra/ExistePeriodoActivoAsync`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
      },
    }).then(response => {
      if (!response.ok) throw Error(response.status.toString());
      return response.json() as Promise<any>;
    }).then(data => {
      resolve({
        data,
        msje: 'SUCCESS',
        friendlyMessage: 'Datos agregados correctamente',
        ok: true
      });
    }).catch(err => {
      reject(err);
    });
  });
}