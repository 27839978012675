import apiConfig from '../../config';
import { IRequest, IUsuarioResponse } from '../../shared/interfaces';

import { IPayloadLogin } from "./interfaces";

const defaultHeader: HeadersInit = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
};

// A mock function to mimic making an async request for data
export function fetchLogin(payload: IPayloadLogin): Promise<IRequest> {
    return new Promise<IRequest>((resolve, reject) => {
        fetch(`${apiConfig.apiUri}/api/login/login`, {
            method: 'POST',
            headers: defaultHeader,
            body: JSON.stringify({
                usuario: payload.user,
                password: payload.pass,
            }),
        }).then( response => {
            if (!response.ok) throw Error(response.status.toString());
            return response.json() as Promise<IUsuarioResponse>;
        }).then( data => {
            resolve({
                data: data.usuarioLogin,
                msje: 'SUCCESS',
                friendlyMessage: 'Usuario conectado correctamente.',
                ok: true
            });
        }).catch( err => {
            reject(err);
        });
    });
}