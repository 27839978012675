export enum ENUMDOCUMENTSTATUS {
    CARGADO = 1,
    PENDIENTE = 3,
    ACEPTADO = 4,
    PROCESADO = 2
};

export enum ENUMDOCUMENTTYPE {
    CONTRATISTAS_PERMANENTES = 1,
    DOTACION_MINERA = 2,
    DOTACION_PROVEEDORA = 3,
};