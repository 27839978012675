import * as React from 'react';

interface ILoadingProps {
    message?: string;
}

const Loading: React.FunctionComponent<ILoadingProps> = (props) => {
    const { message } = props;

    return(
        <div className='w-100 text-primary'>
            <div className="d-flex justify-content-between align-items-center">
                {message? (<strong>{message}</strong>):(<strong>Cargando...</strong>)}
                <div className="spinner-border spinner-border-lg" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
            </div>
        </div>
        
    );

}

export default Loading;