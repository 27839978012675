import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IPayloadLogin } from '../Main/interfaces';
import { login, handleLoading, loginError, loading, loginErrorMessage } from '../Main/slice';

const Login: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const msje: string = useAppSelector(loginError);
    const loginLoading: boolean = useAppSelector(loading);

    const [loginPayload, setloginPayload] = React.useState<IPayloadLogin>({
        user: "",
        pass: "",
    });

    function signIn(event: any): void {
        event.preventDefault();
        if (loginPayload.user === "" || loginPayload.pass === "") {
            dispatch(loginErrorMessage("Ingrese nombre de usuario y contraseña."));
            return;
        }
        dispatch(handleLoading(true));
        dispatch(login(loginPayload));
    }

    function onChange(event: any): void {
        const { id, value } = event.target;
        let loginPayloadModified: IPayloadLogin = loginPayload;
        dispatch(loginErrorMessage(""));

        switch (id) {
            case "inputUser":
                loginPayloadModified = {
                    ...loginPayload,
                    user: value
                };
                break;
            case "inputPassword":
                loginPayloadModified = {
                    ...loginPayload,
                    pass: value
                };
                break;
        }

        setloginPayload(loginPayloadModified);
    }

    return (
        <div className="container">
            <div className="box-login">
                <div className="d-flex-login">
                    <div className="login-user">
                        <div className="logo">
                            <img className='logo-png' src="/images/logo.png" alt="Logo" />
                        </div>
                        <h1>INGRESO</h1>
                        <p className="form-label">Plataforma Levantamiento de Datos CCM-Eleva</p>
                        <br></br>
                        <form onSubmit={signIn}>
                            <div className="mb-3">
                                <label className="form-label">Correo electrónico</label>
                                <input type="email" className="form-control" id="inputUser" placeholder="Email" onChange={onChange} />
                            </div>
                            <div className="mb-3 row">
                                <div className="col">
                                    <label className="form-label">Contraseña</label>
                                    <input type="password" className="form-control" placeholder='Contraseña' id="inputPassword" onChange={onChange} />
                                </div>
                            </div>
                            <button className="btn btn-saesa btn-secondary-fch-login" type="submit" disabled={loginLoading}>
                                {loginLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                {loginLoading ? <span className='px-2'>Ingresando...</span> : <span>Ingresar</span>}
                            </button>
                            <p></p>
                            {msje !== "" && 
                            <div className="error"><p>* {msje} *</p></div>}
                            <br></br>
                            <div className="form-label">Para recuperar contraseña, por favor escríbenos a: <a href="mailto: estudiofuerzalaboral@consejominero.cl">estudiofuerzalaboral@consejominero.cl</a></div>
                        </form>
                    </div>
                    <div className="login-info bg-saesa">
                        <img src="images/login_mineria.jpg" alt='login_mineria' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;