import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mainReducer from "../features/Main/slice";
import homeReducer from "../features/Home/slice";

export const store = configureStore({
    reducer: {
        main: mainReducer,
        home: homeReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
