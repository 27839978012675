import * as React from 'react';
import { Layout } from '../Layout/index';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';
import protectedRoutes from '../../config/routes';
import Header from '../Header';
import Alert from '../Header/alert';

export default function ProtectedRoutes () {
    return (
        <Layout>
            <BrowserRouter>
                <Header />
                <Alert />
                <Routes>
                    {protectedRoutes.map( r => (<Route key={r.path} path={r.path} element={React.createElement(r.component)} />))}
                </Routes>
            </BrowserRouter>
        </Layout>
    );
}
