// obtengo el hostname y lo puedo verificar
const hostname = window && window.location && window.location.hostname;

let apiUri = "https://fch-fuerzalaboral-dev-backend.azurewebsites.net";

const AccessTokenKey = `$PB0X$H4R3P01NT$3RV1C3V20201224v2.${hostname}`;

const UserStorageKey = `U$3R$T0R4G3K3Y$PB0XV20201224v2.${hostname}`;
const UserAccessEmail = `U$3R4CC3$$3M41L.${hostname}`;

switch (hostname) {
    case 'localhost':
        apiUri = "https://fch-fuerzalaboral-dev-backend.azurewebsites.net";
        break;
    default:
        break;
}

const config = {
    apiUri,
    AccessTokenKey,
    UserStorageKey,
    UserAccessEmail,
};

export default config;
