import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { IContratistaPermanenteRequest, IDocument, IDotacionMineraRequest, IDotacionProveedoraRequest, IDownloadFileRequest, IFaena, IPeriodo, ITipo } from '../../shared/interfaces';
import { pushToast } from '../Main/slice';
import { fetchFormsList, fetchFaenasListByEmpresa, postPlantillaContratista, postPlantillaDotacionMinera, postPlantillaDotacionProveedora, fetchTipoDocumento, downloadDocumentoApi, fetchPeriodoActivo, postPlantillaDotacionProveedoraFile, postPlantillaDotacionMineraFile, postPlantillaContratistaFile } from './api';

export interface HomeState {
    loadingDocuments: boolean;
    loadingFaenas: boolean;
    documentsList?: IDocument[];
    faenasList?: IFaena[];
    loadingCarga: boolean;
    tiposList?: ITipo[];
    loadingTipo: boolean;
    loadingPeriodo: boolean;
    downloading: boolean;
    periodo?: IPeriodo;
}

const initialState: HomeState = {
    loadingDocuments: false,
    loadingFaenas: false,
    loadingCarga: false,
    loadingTipo: false,
    downloading: false,
    loadingPeriodo: false,
};

export const homeSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        handleLoadingDocumentsList: (state, action: PayloadAction<boolean>) => {
            state.loadingDocuments = action.payload;
        },
        setDocumentsList: (state, action: PayloadAction<IDocument[]>) => {
            state.loadingDocuments = false;
            state.documentsList = action.payload;
        },
        clearDocumentsList: (state) => {
            state.loadingDocuments = false;
            state.documentsList = undefined;
        },
        handleLoadingPeriodo: (state, action: PayloadAction<boolean>) => {
            state.loadingPeriodo = action.payload;
        },
        setPeriodo: (state, action: PayloadAction<IPeriodo>) => {
            state.loadingPeriodo = true;
            state.periodo = action.payload;
        },
        setFaenasList: (state, action: PayloadAction<IFaena[]>) => {
            state.faenasList = action.payload;
            state.loadingFaenas = false;
        },
        handleloadingFaenasList: (state, action: PayloadAction<boolean>) => {
            state.loadingFaenas = action.payload;
        },
        clearFaenasList: (state) => {
            state.faenasList = undefined;
            state.loadingFaenas = false;
        },
        handleLoadingCarga: (state, action: PayloadAction<boolean>) => {
            state.loadingCarga = action.payload;
        },
        setTiposList: (state, action: PayloadAction<ITipo[]>) => {
            state.tiposList = action.payload;
            state.loadingTipo = false;
        },
        handleLoadingTipo: (state, action: PayloadAction<boolean>) => {
            state.loadingTipo = action.payload;
        },
        handleDownloading: (state, action: PayloadAction<boolean>) => {
            state.downloading = action.payload;
        },
    },
});

export const { handleLoadingDocumentsList, clearDocumentsList, handleloadingFaenasList, clearFaenasList, handleLoadingCarga, handleLoadingTipo, handleDownloading, setTiposList, setFaenasList, handleLoadingPeriodo, setPeriodo} = homeSlice.actions;
const { setDocumentsList } = homeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const documentsList = (state: RootState) => state.home.documentsList;
export const faenasList = (state: RootState) => state.home.faenasList;
export const tiposList = (state: RootState) => state.home.tiposList;
export const loadingDocuments = (state: RootState) => state.home.loadingDocuments;
export const loadingFaenas = (state: RootState) => state.home.loadingFaenas;
export const loadingCarga = (state: RootState) => state.home.loadingCarga;
export const loadingTipo = (state: RootState) => state.home.loadingTipo;
export const downloading = (state: RootState) => state.home.downloading;
export const periodo = (state: RootState) => state.home.periodo;
export const loadingPeriodo = (state: RootState) => state.home.loadingPeriodo;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const getDocumentsList = (idEmpresa: number): AppThunk => async (dispatch) => {
    try {
        const response = await fetchFormsList(idEmpresa);
        dispatch(setDocumentsList(response.data));
    } catch (err: any){
        dispatch(pushToast({
            message: err.friendlyMessage,
            type: 'error',
        }));
        dispatch(handleLoadingDocumentsList(false));
    }
};

export const getPeriodoActivo = (): AppThunk => async (dispatch) => {
    try {
        const response = await fetchPeriodoActivo();
        dispatch(setPeriodo(response.data));
    } catch (err: any){
        dispatch(pushToast({
            message: err.friendlyMessage,
            type: 'error',
        }));
        dispatch(handleLoadingPeriodo(false));
    }
};

export const getFaenasList = (idEmpresa: number): AppThunk => async (dispatch) => {
    try {
        const response = await fetchFaenasListByEmpresa(idEmpresa);
        dispatch(setFaenasList(response.data));
    } catch (err: any){
        dispatch(pushToast({
            message: err.friendlyMessage,
            type: 'error',
        }));
        dispatch(handleloadingFaenasList(false));
    }
};

export const getDocumentosTipoList = (idEmpresa: number, idFaena?: number): AppThunk => async (dispatch) => {
    try {
        const response = await fetchTipoDocumento(idEmpresa, idFaena);
        dispatch(setTiposList(response.data));
    }catch(err: any){
        dispatch(pushToast({
            message: err.friendlyMessage,
            type: 'error',
        }));
        dispatch(handleLoadingTipo(false));
    }
};

export const setPlantillaContratista = (request: IContratistaPermanenteRequest) : AppThunk => async (dispatch) => {
    try {
        const responseId = await postPlantillaContratista(request);
        console.log(responseId);
        postPlantillaContratistaFile(request, responseId.data);
        dispatch(handleLoadingCarga(false));
        dispatch(pushToast({
            message: `Archivo "Plantilla Contratista" procesado correctamente con [${responseId.msje}] valores.`,
            type: 'success',
        }));
    } catch(err: any){
        dispatch(pushToast({
            message: err.toString(),
            type: 'error',
        }));
        dispatch(handleLoadingCarga(false));
    }
};

export const setPlantillaDotacionMinera = (request: IDotacionMineraRequest) : AppThunk => async (dispatch) => {
    try{
        const responseId = await postPlantillaDotacionMinera(request);
        console.log(responseId);
        await postPlantillaDotacionMineraFile(request, responseId.data)
        dispatch(handleLoadingCarga(false));
        dispatch(pushToast({
            message: `Archivo "Plantilla Dotación Minera" procesado correctamente con [${responseId.msje}] valores.`,
            type: 'success',
        }));
    } catch(err: any){
        dispatch(pushToast({
            message: err.toString(),
            type: 'error',
        }));
        dispatch(handleLoadingCarga(false));
    }
};

export const setPlantillaDotacionProveedora = (request: IDotacionProveedoraRequest) : AppThunk => async (dispatch) => {
    try{
        const responseId = await postPlantillaDotacionProveedora(request);
        console.log(responseId);
        await postPlantillaDotacionProveedoraFile(request, responseId.data);
        dispatch(handleLoadingCarga(false));
        dispatch(pushToast({
            message: `Archivo "Plantilla Dotación Proveedora" procesado correctamente con [${responseId.msje}] valores.`,
            type: 'success',
        }));
    } catch(err: any){
        dispatch(pushToast({
            message: err.toString(),
            type: 'error',
        }));
        dispatch(handleLoadingCarga(false));
    }
};

export const downloadDocumento = (request: IDownloadFileRequest): AppThunk => async (dispatch) => {
    try{
        const response = await downloadDocumentoApi(request);
        dispatch(pushToast({
            message: response.friendlyMessage,
            type: 'success',
        }));
        dispatch(handleDownloading(false));
    } catch(err: any){
        dispatch(pushToast({
            message: err.toString(),
            type: 'error',
        }));
        dispatch(handleDownloading(false));
    }
}

export default homeSlice.reducer;
