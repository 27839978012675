import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IUser} from '../../shared/interfaces';
import { logout, handleLoading, user } from '../Main/slice';

const Header: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userConnected: IUser | undefined = useAppSelector(user);

    React.useEffect(() => {
        dispatch(handleLoading(true));
    },[dispatch]);

    function signOut() {
        dispatch(logout());
    }

    function onGoHome (){
        navigate('/');
    }

    return(
        <nav className="navbar-pmgd navbar-expand-lg navbar-light">
            <div className="d-flex-nav">
                        <img className='logo-png' src="images/logo.png" alt='logo' />
                <div className="user-box">
                    <div className="avatar-user">
                        <div className="name-user">{userConnected? userConnected.nombreUsuario: 'unknown'}</div>
                    </div>
                    <div className="drop-user">
                        <div className="dropdown">
                            <button className="btn-more-menu" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="images/more.svg" alt='more'/>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <button className='dropdown-item' onClick={signOut} >Cerrar sesión</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
export default Header;