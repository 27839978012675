import apiConfig from '../../config';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IPayloadLogin, IToastMessage } from './interfaces';
import { fetchLogin } from './api';
import { IUser } from '../../shared/interfaces';

export interface MainState {
    isAuthenticated: boolean;
    loading: boolean;
    user?: IUser;
    toastMessage: IToastMessage[];

    loginError: string;
}

const initialState: MainState = {
    isAuthenticated: false,
    loading: false,
    toastMessage: [],
    loginError: "",
};

// The function below is called a thunk and allows us to perform async logic.
export const login = createAsyncThunk(
    'main/fetchLogin',
    async (loginPayload: IPayloadLogin) => {
        const response = await fetchLogin(loginPayload);
        return response.data;
    }
);

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        handleLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        reloadLogin: (state, action: PayloadAction<IUser>) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
        },
        logout: (state) => {
            sessionStorage.clear();
            state.loading = false;
            state.isAuthenticated = false;
        },
        pushToast: (state, action: PayloadAction<IToastMessage>) => {
            const list = state.toastMessage;
            state.toastMessage.push(action.payload);
            state.toastMessage = list;
        },
        popToast: (state) => {
            const list = state.toastMessage;
            state.toastMessage.pop();
            state.toastMessage = list;
        },
        loginErrorMessage: (state, action: PayloadAction<string>) => {
            state.loginError = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loginError = "";
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<IUser>) => {

                // restore session store
                sessionStorage.clear();
                state.loading = false;

                if (!action.payload.estadoUsuario) {
                    state.isAuthenticated = false;
                    state.loginError = action.payload.mensajeError;
                    return;
                }

                state.isAuthenticated = true;
                state.user = action.payload;
                
                // session store
                sessionStorage.setItem(apiConfig.UserStorageKey, JSON.stringify(action.payload));
                sessionStorage.setItem(apiConfig.AccessTokenKey, action.payload.token);
                sessionStorage.setItem(apiConfig.UserAccessEmail, action.payload.usuario);
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.loginError = action.error.message!;
            });
    },
});

export const { handleLoading, logout, reloadLogin, pushToast, popToast, loginErrorMessage } = mainSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const isAuthenticated = (state: RootState) => state.main.isAuthenticated;
export const loading = (state: RootState) => state.main.loading;
export const user = (state: RootState) => state.main.user;
export const alertToast = (state: RootState) => state.main.toastMessage;
export const loginError = (state: RootState) => state.main.loginError;

export default mainSlice.reducer;