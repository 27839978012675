/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loading from '../../shared/components/Loading';
import { ENUMDOCUMENTTYPE } from '../../shared/enums';
import { IDocument, IFaena, IPeriodo, ITipo, IUser } from '../../shared/interfaces';
import { logout, pushToast, user } from '../Main/slice';
import ItemProcesed from './ItemProcesed';
import {
    getDocumentsList,
    getFaenasList,
    handleLoadingDocumentsList,
    handleloadingFaenasList,
    loadingFaenas,
    loadingDocuments,
    faenasList,
    documentsList,
    handleLoadingCarga,
    loadingCarga,
    setPlantillaContratista,
    setPlantillaDotacionMinera,
    setPlantillaDotacionProveedora,
    getDocumentosTipoList,
    handleLoadingTipo,
    tiposList,
    loadingTipo,
    downloading,
    downloadDocumento,
    handleDownloading,
    setTiposList,
    periodo,
    getPeriodoActivo,
    handleLoadingPeriodo,
} from './slice';
import readXlsxFile from 'read-excel-file'
import { fileRowToPlantillaContratistas, fileRowToPlantillaDotacionMinera, fileRowToPlantillaDotacionProveedor } from '../../shared/utils';
import LoadingInput from '../../shared/components/LoadingInput';
import Modal from './simpleModal';
import moment from 'moment';
import 'moment/locale/es';

interface IModalOptions {
    bodyMsje: string;
    show: boolean;
    fijo: boolean;
    handleClose: () => void;
}

const Home: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const listDocus: IDocument[] | undefined = useAppSelector(documentsList);
    const listFaenas: IFaena[] | undefined = useAppSelector(faenasList);
    const listTipo: ITipo[] | undefined = useAppSelector(tiposList);

    const documentoLoading: boolean = useAppSelector(loadingDocuments);
    const faenasLoading: boolean = useAppSelector(loadingFaenas);
    const cargaLoading: boolean = useAppSelector(loadingCarga);
    const tipoLoading: boolean = useAppSelector(loadingTipo);
    const disableDownload: boolean = useAppSelector(downloading);

    const userLogged: IUser | undefined = useAppSelector(user);
    const periodoActivo: IPeriodo | undefined = useAppSelector(periodo);
    const today: Date = new Date();
    const [esperanza, setEsperanza] = React.useState(false);
    const [btnDescarga, setBtnDescarga] = React.useState(0);
    const [src, setSrc] = React.useState("");

    const [eliminar, setEliminar] = React.useState(false);
    const [docIdentificador, setDocIdentificador] = React.useState(Number);

    const [fileSelected, setFileSelected] = React.useState<File | null>(null);
    const [faneaSelected, setFaenaSelected] = React.useState<number>(0);
    const [randomKey, setRandomKey] = React.useState("");
    const [tipoArchivoSelected, setTipoArchivo] = React.useState<number>(0);
    const [modalOptions, setModalOptions] = React.useState<IModalOptions>({
        bodyMsje: '',
        show: false,
        fijo: true,
        handleClose: () => { return; },
    });

    React.useEffect(() => {
        dispatch(handleloadingFaenasList(true));
        dispatch(handleLoadingPeriodo(true));
        dispatch(handleLoadingDocumentsList(true));
        dispatch(getPeriodoActivo());
        dispatch(getDocumentsList(userLogged!.usuarioEmpresa.empEmpresaId!));
        dispatch(getFaenasList(userLogged!.usuarioEmpresa.empEmpresaId!));
        resetTheFileInput();
    }, []);

    React.useEffect(() => {
        setTimeout(() => onChangeNoFaena(), 100);
        setSrc("data:image/png;base64," + userLogged!.usuarioEmpresa.empEmpresa.empProfilePicture)
    }, [esperanza]);

    React.useEffect(() => {
        if (periodoActivo !== undefined) {
            if (moment(periodoActivo.perInicio).isSameOrBefore(today)) {
                onOpenModal(moment(periodoActivo.perInicio).format('D MMMM'), (moment(periodoActivo.perFin).format('LL')));
            } else {
                openStatic2(moment(periodoActivo.perInicio).format('LL'), (moment(periodoActivo.perFin).format('LL')));
            }
        }
        else {
            openStatic();
        }
    }, [periodoActivo]);

    React.useEffect(() => {
        if (userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 1) {
            onSubmit(faneaSelected);
            dispatch(getDocumentsList(userLogged!.usuarioEmpresa.empEmpresaId!));
        }
        if (userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2) {
            onChangeNoFaena();
            dispatch(getDocumentsList(userLogged!.usuarioEmpresa.empEmpresaId!));
        }
    }, [cargaLoading]);

    function onOpenSelectFile(): void {
        const input = document.getElementById('fileId');
        input?.click();
    }

    function onSelectFile(ev: any): void {
        setFileSelected(ev.target!.files[0] ? ev.target!.files[0] : null);
        for (let i = 0; i < listTipo!.length; i++) {
            if (listTipo![i].enumTipoPlanilla === tipoArchivoSelected) {
                if (listTipo![i].docIdentificador !== null) {
                    setEliminar(true);
                    setDocIdentificador(listTipo![i].docIdentificador);
                } else {
                    setEliminar(false);
                    setDocIdentificador(0);
                }
            }
        }
    }

    function resetTheFileInput() {
        let randomString = Math.random().toString(36);
        setRandomKey(randomString);
      }

    function onDownloadPlantilla(nombreTemplate: string, idBtn: number) {
        setBtnDescarga(idBtn);
        dispatch(handleDownloading(true));
        dispatch(downloadDocumento({
            filename: nombreTemplate
        }));
        setTimeout(() => setBtnDescarga(0), 1000) 
    }

    function actualizarHistorial(): void {
        if (userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 1) {
            onSubmit(faneaSelected);
            dispatch(handleLoadingDocumentsList(true));
            dispatch(getDocumentsList(userLogged!.usuarioEmpresa.empEmpresaId!));
        }
        if (userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2) {
            onChangeNoFaena();
            dispatch(handleLoadingDocumentsList(true));
            dispatch(getDocumentsList(userLogged!.usuarioEmpresa.empEmpresaId!));
        }
    }

    function onUploadFile(): void {
        dispatch(handleLoadingCarga(true));
        var headerValues: any[];
        var sheetDataNumber: number = 0;
        var hasError: boolean = false;

        switch (tipoArchivoSelected) {
            case ENUMDOCUMENTTYPE.CONTRATISTAS_PERMANENTES:
                sheetDataNumber = 1;
                headerValues = [
                    "ID",
                    "Razon Social",
                    "Nombre Empresa Proveedora",
                    "N° de mujeres permanentes en la Faena",
                    "N° de hombres permanentes en la Faena",
                    "N° total de Personas permanentes en la Faena",
                    "Categoría",
                ];
                break;

            case ENUMDOCUMENTTYPE.DOTACION_MINERA:
                sheetDataNumber = 1;
                headerValues = [
                    "RUT / Número Identificador",
                    "Faena/Operación/División/Matriz",
                    "Gerencia",
                    "Subgerencia",
                    "Unidad",
                    "Departamento",
                    "Área",
                    "ID Cargo",
                    "Cargo Específico",
                    "Fecha de nacimiento (día, mes y año)",
                    "Sexo",
                    "Nacionalidad",
                    "Pueblo originario",
                    "Región de Residencia permanente",
                    "Nivel educacional aprobado",
                    "Fecha de ingreso a la empresa (día, mes y año)",
                ];
                break;

            case ENUMDOCUMENTTYPE.DOTACION_PROVEEDORA:
                sheetDataNumber = 1;
                headerValues = [
                    "RUT / Número Identificador",
                    "Gerencia",
                    "Subgerencia",
                    "Unidad",
                    "Departamento",
                    "Área",
                    "ID Cargo",
                    "Cargo Específico",
                    "Lugar de trabajo",
                    "Fecha de nacimiento (día, mes y año)",
                    "Sexo",
                    "Nacionalidad",
                    "Pueblo originario",
                    "Región de Residencia permanente",
                    "Región de trabajo",
                    "Nivel educacional",
                    "Fecha de ingreso a la empresa (día, mes y año)"
                ];
                break;
        }

        // https://www.npmjs.com/package/read-excel-file
        readXlsxFile(fileSelected!, { sheet: sheetDataNumber }).then((rows) => {
            if (rows.length <= 1) {
                dispatch(handleLoadingCarga(false));
                dispatch(pushToast({
                    message: `¡Error! El archivo que intenta subir a la plataforma está en blanco. Favor revisar el archivo cargado.`,
                    type: 'error',
                }));
                setFileSelected(null);
                return;
            }
            // if (rows[0].length !== headerValues.length) {
            //     dispatch(handleLoadingCarga(false));
            //     dispatch(pushToast({
            //         message: `El archivo no tiene la cantidad correcta de elementos, envió ${rows[0].length} / ${headerValues.length}. Verifique el tipo de documento a analizar`,
            //         type: 'error',
            //     }));
            //     return;
            // }

            // valido los nombres del header 1 a 1
            for (let h = 0; h < headerValues.length; h += 1) {
                if (headerValues[h].toUpperCase() !== (rows[0][h] as string).toUpperCase()) {
                    hasError = true;
                    setFileSelected(null);
                    //setTimeout(() => window.location.reload(), 5000) 
                    break;
                }
            }
            if (hasError) {
                dispatch(handleLoadingCarga(false));
                dispatch(pushToast({
                    message: `¡Error! El archivo que intenta subir a la plataforma, es distinto al archivo que se seleccionó previamente. Favor revisar los campos seleccionados.`,
                    type: 'error',
                }));
                return;
            }

            switch (tipoArchivoSelected) {
                case ENUMDOCUMENTTYPE.CONTRATISTAS_PERMANENTES:
                    dispatch(setPlantillaContratista({
                        empEmpresaId: userLogged!.usuarioEmpresa.empEmpresa.empEmpresaId,
                        perPeriodoId: periodoActivo!.perPeriodoId.toString(),
                        estEstadoPlanillaId: 1,
                        usuUsuarioId: userLogged!.usuarioEmpresa.usuUsuarioId,
                        docNombre: fileSelected!.name,
                        faFaenaId: faneaSelected,
                        archivo: fileSelected!,
                        plantillaContratistaPermanenteDto: fileRowToPlantillaContratistas(rows),
                        empRut: userLogged!.usuarioEmpresa.empEmpresa.empRut,
                        periodoA: periodoActivo!.perAnoBase.toString(),
                        docIdentificador: docIdentificador,
                        eliminarDatosAnteriores: eliminar
                    }));
                    setFileSelected(null);
                    break;

                case ENUMDOCUMENTTYPE.DOTACION_MINERA:
                    dispatch(setPlantillaDotacionMinera({
                        empEmpresaId: userLogged!.usuarioEmpresa.empEmpresa.empEmpresaId,
                        perPeriodoId: periodoActivo!.perPeriodoId.toString(),
                        estEstadoPlanillaId: 1,
                        usuUsuarioId: userLogged!.usuarioEmpresa.usuUsuarioId,
                        docNombre: fileSelected!.name,
                        faFaenaId: faneaSelected,
                        archivo: fileSelected!,
                        rut: userLogged!.usuarioEmpresa.empEmpresa.empRut,
                        periodo: periodoActivo!.perAnoBase.toString(),
                        identificadorArchivo: 0,
                        tipoPlanilla: 0,
                        eliminarDatosAnteriores: eliminar,
                        plantillaDotacionMineraDto: fileRowToPlantillaDotacionMinera(rows),
                        docIdentificador: docIdentificador,
                    }));
                    setFileSelected(null);
                    break;

                case ENUMDOCUMENTTYPE.DOTACION_PROVEEDORA:
                    dispatch(setPlantillaDotacionProveedora({
                        empEmpresaId: userLogged!.usuarioEmpresa.empEmpresa.empEmpresaId,
                        perPeriodoId: periodoActivo!.perPeriodoId.toString(),
                        estEstadoPlanillaId: 1,
                        usuUsuarioId: userLogged!.usuarioEmpresa.usuUsuarioId,
                        docNombre: fileSelected!.name,
                        faFaenaId: faneaSelected,
                        archivo: fileSelected!,
                        plantillaDocDotacionProveedoraDto: fileRowToPlantillaDotacionProveedor(rows),
                        empRut: userLogged!.usuarioEmpresa.empEmpresa.empRut,
                        periodoA: periodoActivo!.perAnoBase.toString(),
                        docIdentificador: docIdentificador,
                        eliminarDatosAnteriores: eliminar,
                    }));
                    setFileSelected(null);
                    break;
            }
        });
        setTipoArchivo(0);
        resetTheFileInput();
    }

    function onOpenModal(fechaInicio: string, fechaTermino: string): void {
        setModalOptions({
            bodyMsje: `Periodo de captura de datos activo desde el ${fechaInicio} hasta el  ${fechaTermino} `,
            show: true,
            fijo: false,
            handleClose: () => {
                onCloseModal();
                if (userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2) {
                    setEsperanza(true);
                }
            },
        });
    }

    function openStatic(): void {
        setModalOptions({
            bodyMsje: 'En estos momentos no se encuentra disponible el periodo de captura de datos.',
            show: true,
            fijo: true,
            handleClose: () => {
                dispatch(logout());
            },
        });
    }

    function openStatic2(fechaInicio: string, fechaTermino: string): void {
        setModalOptions({
            bodyMsje: `El periodo de Captura de Datos aún no comienza. Periodo de captura de datos activo desde el ${fechaInicio} al ${fechaTermino}`,
            show: true,
            fijo: true,
            handleClose: () => {
                dispatch(logout());
            },
        });
    }

    function onCloseModal(): void {
        setModalOptions({
            bodyMsje: '',
            show: false,
            fijo: false,
            handleClose: () => { return; },
        });
    }

    function onChangeFaena(ev: any) {
        let faenaId: number = Number(ev.target.value);
        setTipoArchivo(0);
        setFaenaSelected(faenaId);
        dispatch(setTiposList([]));
        if (faenaId !== 0) {
            dispatch(getDocumentosTipoList(userLogged!.usuarioEmpresa.empEmpresaId!, faenaId));
            dispatch(handleLoadingTipo(true));
        }
    }

    function onSubmit(faenaId: number) {
        dispatch(setTiposList([]));
        if (faenaId !== 0) {
            dispatch(getDocumentosTipoList(userLogged!.usuarioEmpresa.empEmpresaId!, faenaId));
            dispatch(handleLoadingTipo(true));
        }
    }

    function onChangeNoFaena() {
        dispatch(setTiposList([]));
        dispatch(getDocumentosTipoList(userLogged!.usuarioEmpresa.empEmpresaId!));
        dispatch(handleLoadingTipo(true));
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="box-form pt-5">
                        <div className="head-page-form">
                            <div className="info-page">
                                <div className="title">Levantamiento de datos “Estudio de Fuerza Laboral de la Gran Minería Chilena 2023-2032”</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="box-form pt-5">
                        <div className="head-page-form">
                            <div className="info-page">
                                <div className="sub-title-1">Te damos la bienvenida a la plataforma de captura de datos CCM-Eleva, mediante la cual podrás descargar las plantillas y cargar la información necesaria para la construcción del Estudio de Fuerza Laboral de la Gran Minería Chilena 2023 - 2032.</div>
                                <div className="sub-title-1"><b>Por favor, antes de cargar la plantilla dotacional de tu empresa en el sistema, te solicitamos</b> verificar que la información contenida responde a lo solicitado, ya que estos reportes son parte importante de Estudio de Fuerza Laboral.</div>
                                <div className="sub-title-1">La información entregada es analizada con estricta confidencialidad por el equipo del área de estudios de Fundación Chile. Agradecemos tu participación.</div>
                                <div className="sub-title-1"><b>Fecha corte de trabajadores activos al 30 de noviembre 2022.</b></div>
                                <div className="sub-title-1">*correo contacto: <a href="mailto: estudiofuerzalaboral@consejominero.cl">estudiofuerzalaboral@consejominero.cl</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row' style={{ marginTop: 40 }}>
                <div className="col-lg-2 col-sm-2 col-xs-12"></div>
                <div className="col-lg-8 col-sm-8 col-xs-12">
                    <h3 className='descarga-p'>Descarga de plantillas</h3>
                    {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 1 &&
                        <><ul className='download-list'>
                            <li key="dotacionMinera">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='title'>Planilla Dotación Minera</span>
                                    <button className="btn btn-saesa btn-secondary-fch px-5" disabled={btnDescarga === 1} onClick={() => onDownloadPlantilla("Template_Dotacion_Minera.xlsx", 1)}>
                                        <span>Descargar plantilla  </span>
                                        {btnDescarga === 1 && disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                    </button>
                                </div>
                            </li>
                        </ul><ul className='download-list'>
                                <li key="contratistasPermanentes">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className='title'>Planilla Contratistas Permanentes</span>
                                        <button className="btn btn-saesa btn-secondary-fch px-5" disabled={btnDescarga === 2} onClick={() => onDownloadPlantilla("Template_Contratista_Permanente.xlsx", 2)}>
                                            <span>Descargar plantilla  </span>
                                            {btnDescarga === 2 && disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                        </button>
                                    </div>
                                </li>
                            </ul></>
                    }
                    {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 3 &&
                        <><ul className='download-list'>
                            <li key="dotacionMinera">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='title'>Planilla Dotación Minera</span>
                                    <button className="btn btn-saesa btn-secondary-fch px-5" disabled={btnDescarga === 1} onClick={() => onDownloadPlantilla("Template_Dotacion_Minera.xlsx", 1)}>
                                        <span>Descargar plantilla  </span>
                                        {btnDescarga === 1 && disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                    </button>
                                </div>
                            </li>
                        </ul><ul className='download-list'>
                                <li key="contratistasPermanentes">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className='title'>Planilla Contratistas Permanentes</span>
                                        <button className="btn btn-saesa btn-secondary-fch px-5" disabled={btnDescarga === 2} onClick={() => onDownloadPlantilla("Template_Contratista_Permanente.xlsx", 2)}>
                                            <span>Descargar plantilla  </span>
                                            {btnDescarga === 2 && disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                        </button>
                                    </div>
                                </li>
                            </ul>
                            <ul className='download-list'>
                                <li key="contratistasPermanentes">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className='title'>Planilla Dotación Proveedoras</span>
                                        <button className="btn btn-saesa btn-secondary-fch px-5" disabled={btnDescarga === 3} onClick={() => onDownloadPlantilla("Template_Dotacion_Proveedora.xlsx", 3)}>
                                            <span>Descargar plantilla  </span>
                                            {btnDescarga === 3 && disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                        </button>
                                    </div>
                                </li>
                            </ul></>
                    }
                    {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2 &&
                        <ul className='download-list'>
                            <li key="contratistasPermanentes">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='title'>Planilla Dotación Proveedoras</span>
                                    <button className="btn btn-saesa btn-secondary-fch px-5" disabled={disableDownload} onClick={() => onDownloadPlantilla("Template_Dotacion_Proveedora.xlsx", 3)}>
                                        <span>Descargar plantilla  </span>
                                        {disableDownload && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    }
                    <div className="col-lg-2 col-sm-2 col-xs-12"></div>
                </div>
            </div>
            <div className="form-box">
                <div className="row">
                    <div className="col-lg-2 col-sm-2 col-xs-12"></div>
                    <div className="col-lg-8 col-sm-8 col-xs-12">
                        <div className="tabs-list pt-5">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className='row'>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            {userLogged?.usuarioEmpresa.empEmpresa.empSocia === true &&
                                                <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <h3 className='descarga-p'>Historial archivo procesado</h3>
                                                        <br></br>
                                                        <button className="btn btn-saesa btn-secondary-fch2 px-5" disabled={documentoLoading} onClick={actualizarHistorial}>
                                                            <span>Actualizar historial  </span>
                                                            {documentoLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                        </button>
                                                    </li>
                                                </ul>
                                            }
                                            {userLogged?.usuarioEmpresa.empEmpresa.empSocia === false &&
                                                <ul className="nav nav-pills mb-5" id="pills-tab-2" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <h3 className='descarga-p'>Historial archivo procesado</h3>
                                                        <br></br>
                                                        <button className="btn btn-saesa btn-secondary-fch2 px-5" disabled={documentoLoading} onClick={actualizarHistorial}>
                                                            <span>Actualizar historial  </span>
                                                            {documentoLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                        </button>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            {userLogged?.usuarioEmpresa.empEmpresa.empSocia === true &&
                                                <div className='div-logo'>
                                                    <img src={src} className="logo-empresa" alt="logo empresa"></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div id="item-form-titulos">
                                        <div className="container">
                                            <div className="row center">
                                                <div className="col-lg-2 col-sm-2 col-xs-2">
                                                    <div className="item-document">
                                                        <span className="title-item">Nombre faena</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-2 col-xs-2">
                                                    <div className="item-document">
                                                        <div className="title-item">Tipo planilla</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-xs-4">
                                                    <div className="item-document">
                                                        <span className="title-estado">Estado</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-2 col-xs-2">
                                                    <div className="item-document">
                                                        <span className="title-item">Fecha</span>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 col-sm-2 col-xs-2">
                                                    <div className="item-document">
                                                        <span className="title-item">Usuario</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='scrollable'>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-proceso" role="tabpanel" aria-labelledby="pills-proceso">
                                                {documentoLoading && <Loading />}
                                                <div className="list-item">
                                                    {listDocus && listDocus.map((item: IDocument) => (
                                                        <ItemProcesed key={item.docIdentificadorShp} item={item} />
                                                    ))}
                                                    {listDocus?.length === 0 &&
                                                        <h6>No existen archivos cargados</h6>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <h3 className='descarga-p'>Carga de archivos</h3>
                        <div className="row">
                            <div className="form-box col-lg-4 col-xs-12">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="usuarioEmpresa" value={userLogged?.usuarioEmpresa.empEmpresa.empNombre} readOnly />
                                </div>
                            </div>
                            {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 1 &&
                                <><div className="form-box col-lg-4 col-xs-12">
                                    <div className="form-group">
                                        {faenasLoading && <LoadingInput />}
                                        {listFaenas && listFaenas.length > 0 && (
                                            <select disabled={fileSelected !== null || cargaLoading} className="form-control form-select" id="faena" onChange={onChangeFaena}>
                                                <option value={0}>Seleccione faena</option>
                                                {listFaenas!.map(f => (
                                                    <option key={f.faFaenaId} value={f.faFaenaId}>{f.faNombre}</option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div><div className="form-box col-lg-4 col-xs-12">
                                        <div className="form-group">
                                            {tipoLoading && <LoadingInput />}
                                            {listTipo && listTipo.length > 0 ? (
                                                <select disabled={fileSelected !== null || cargaLoading} className="form-control form-select" id="tipoArchivo" onChange={ev => { setTipoArchivo(Number(ev.target.value)); }}>
                                                    <option value={0}>Seleccione tipo de archivo</option>
                                                    {listTipo!.map(t => (
                                                        <option key={t.enumTipoPlanilla} value={t.enumTipoPlanilla}>{t.tipoPlanilla}</option>
                                                    ))}
                                                </select>
                                            ) : !tipoLoading && (<LoadingInput message='Sin planillas que mostrar' />)}
                                        </div>
                                    </div></>
                            }
                            {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2 &&
                                <div className="form-box col-lg-4 col-xs-12">
                                    <div className="form-group">
                                        {tipoLoading && <LoadingInput />}
                                        {listTipo && listTipo.length > 0 ? (
                                            <select disabled={fileSelected !== null || cargaLoading} className="form-control form-select" id="tipoArchivo" onChange={ev => { setTipoArchivo(Number(ev.target.value)) }}>
                                                <option value={0}>Seleccione tipo de archivo</option>
                                                {listTipo!.map(t => (
                                                    <option key={t.enumTipoPlanilla} value={t.enumTipoPlanilla}>{t.tipoPlanilla}</option>
                                                ))}
                                            </select>
                                        ) : !tipoLoading && (<LoadingInput message='Planillas ya cargadas' />)}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="box-upload">
                            <div className="container">
                                <div className="card">
                                    {userLogged?.usuarioEmpresa.rolRoLid === 2 &&
                                        <div className="drop_box">
                                            <header style={{ 'cursor': 'pointer', 'textAlign': 'center' }} onClick={onOpenSelectFile}>
                                                <img src="images/cloudup.png" className="imgup" alt='cloudup' />
                                            </header>
                                            {tipoArchivoSelected === 0 &&
                                                <h4 className='disabled'>Seleccione un tipo archivo primero</h4>
                                            }
                                            {tipoArchivoSelected !== 0 &&
                                                <h4>{fileSelected === null ? 'Subir Archivo' : `${fileSelected.name}`}</h4>
                                            }
                                            <p>Archivos soportados: XLS, XLSX</p>
                                            <input key={randomKey} type="file" hidden accept=".xlsx,xls" id="fileId" style={{ 'display': 'none' }} onChange={onSelectFile} disabled={tipoArchivoSelected === 0} />
                                            {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 1 &&
                                                <button className="btn btn-primary-fch" disabled={fileSelected === null || faneaSelected === 0 || tipoArchivoSelected === 0 || cargaLoading} onClick={onUploadFile}>
                                                    {cargaLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                    {cargaLoading ? <span className='px-2'>Cargando...</span> : <span>Enviar</span>}
                                                </button>
                                            }
                                            {userLogged?.usuarioEmpresa.empEmpresa.tipTipoEmpresaId === 2 &&
                                                <button className="btn btn-primary-fch" disabled={tipoArchivoSelected === 0 || cargaLoading || fileSelected === null} onClick={onUploadFile}>
                                                    {cargaLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                    {cargaLoading ? <span className='px-2'>Cargando...</span> : <span>Enviar</span>}
                                                </button>
                                            }
                                        </div>
                                    }
                                    {userLogged?.usuarioEmpresa.rolRoLid === 3 &&
                                        <div className="drop_box">
                                            <h4>Usted no puede realizar la carga de archivos</h4>
                                        </div>
                                    }
                                    {userLogged?.usuarioEmpresa.rolRoLid === 1 &&
                                        <div className="drop_box">
                                            <h4>Usted no puede realizar la carga de archivos</h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-xs-12"></div>
                    <Modal {...modalOptions} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="box-form pt-5">
                        <div className="head-page-form">
                            <div className="info-page">
                                <div className="sub-title">En caso de dudas sobre el proceso o sobre el estudio, por favor escríbenos a: <a href="mailto: estudiofuerzalaboral@consejominero.cl">estudiofuerzalaboral@consejominero.cl</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;