import Home from '../features/Home';
import Login from '../features/Login';

interface IRoutes {
    path: string;
    component: any;
}

export const paths = {
    any: '*',
    root: '/',

    form:'/form',
    formlist: '/form-list',
};

export const publicRoutes: IRoutes[] = [
    { path: paths.root, component: Login },
    { path: paths.any, component: Login },
];

const privateRoutes: IRoutes[] = [
    { path: paths.root, component: Home },
    { path: paths.any, component: Home },
]; 

export default privateRoutes;
