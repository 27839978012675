import * as React from 'react';
import apiConfig from '../../config';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ProtectedRoutes from './Protected';
import { PublicRoutes } from './Public';
import { isAuthenticated, reloadLogin, handleLoading, logout, pushToast } from './slice';

const Main: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(handleLoading(true));
        const userStorage = sessionStorage.getItem(apiConfig.UserStorageKey);
        const UserAccesEmail = sessionStorage.getItem(apiConfig.UserAccessEmail);
    
        if (userStorage) {
            dispatch(pushToast({
                message: 'Conectado correctamente.',
                type: 'success'
            }));
            dispatch(reloadLogin(JSON.parse(userStorage)));
        } else {
            dispatch(logout());
            dispatch(handleLoading(false));
        }
    }, [dispatch]);

    const auth: boolean = useAppSelector(isAuthenticated);
    return auth ? <ProtectedRoutes /> : <PublicRoutes />;
};

export default Main;