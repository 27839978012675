import * as React from 'react';

interface ILoadingInputProps {
    message?: string;
}

const LoadingInput: React.FunctionComponent<ILoadingInputProps> = (props) => {
    const { message } = props;

    return(
        <div className='w-100 text-primary'>
            <div className="d-flex justify-content-between align-items-center">
                <input className='form-control' placeholder={message? message: 'Cargando...'} readOnly />
            </div>
        </div>
        
    );

}

export default LoadingInput;