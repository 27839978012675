import * as React from 'react';
import { ENUMDOCUMENTSTATUS } from '../../shared/enums';
import { IDocument } from '../../shared/interfaces';
import moment from 'moment-timezone';
import 'moment/locale/es';

interface ItemProcesedProps {
    item: IDocument;
}

const ItemProcesed: React.FunctionComponent<ItemProcesedProps> = (props) => {

    const { item } = props;

    function setStatusImg(statusId: number): JSX.Element {
        switch (statusId) {
            case ENUMDOCUMENTSTATUS.CARGADO:
                return (<img src="images/estado01.png" alt={statusId.toString()} />);
            case ENUMDOCUMENTSTATUS.PENDIENTE:
                return (<img src="images/estado02-naranjo.png" alt={statusId.toString()} />);
            case ENUMDOCUMENTSTATUS.ACEPTADO:
                return (<img src="images/estado03-verde.png" alt={statusId.toString()} />);
            case ENUMDOCUMENTSTATUS.PROCESADO:
                return (<img src="images/estado04.png" alt={statusId.toString()} />);
            default:
                return (<img src='images/not.png' alt='undefined' />);
        }
    }

    function setStatusNombre(statusId:number): JSX.Element {
        switch (statusId) {
            case ENUMDOCUMENTSTATUS.CARGADO:
                return (<span className="item-estado-revision">Archivo cargado pendiente de revisión</span>);
            case ENUMDOCUMENTSTATUS.PENDIENTE:
                return (<span className="item-estado">Archivo revisado, resultado con observaciones</span>);
            case ENUMDOCUMENTSTATUS.ACEPTADO:
                return (<span className="item-estado-aprobado">Archivo aprobado</span>);
            case ENUMDOCUMENTSTATUS.PROCESADO:
                return (<span className="item-estado">Archivo cargado pendiente de revisión</span>);
            default:
                return (<img src='images/not.png' alt='undefined' />);
        }
    }

    return (
        <div id="item-form">
            <div className="container">
                <div className="row center">
                    <div className="col-lg-2 col-sm-2 col-xs-2">
                        <div className="item-document">
                            <span className="title-item">{item.faNombre === null && "Sin faena"}
                            {item.faNombre !== null && item.faNombre}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-xs-2">
                    <div className="item-document">
                        <div className="title-item">{item.tipoPlanilla}</div>
                    </div>
                    </div>

                    <div className="col-lg-4 col-sm-4 col-xs-4">
                        <div className="status">
                            <div className="icon-estatus">{setStatusImg(item.estEstadoPlanillaId)}</div>
                            {setStatusNombre(item.estEstadoPlanillaId)}
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-2 col-xs-2">
                        <div className="item-document">
                            <span className="title-item"> {moment(item.fechaCarga).tz("America/Mexico_City").format('l LT')} </span>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-2 col-xs-2">

                        <div className="item-document">
                        <span className='title-estado'>{item.usuNombre}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemProcesed;